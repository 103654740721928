<template>
  <div>
    <el-row :gutter="5" type="flex">
      <el-col :span="15">
        <span class="inner-title">{{
          __("Assign path to Return value name")
        }}</span>
      </el-col>
    </el-row>
    <el-row :gutter="5" type="flex">
      <el-col :span="15">
        <el-input
          :placeholder="__('Return value name')"
          v-model="ruleConfigurer.output_name"
        ></el-input>
      </el-col>
      <el-col :span="9">
        <el-button
          type="primary"
          :disabled="!ruleConfigurer.output_name || disableSubmit"
          id="addReturnValueNameBtn"
          @click="addReturnValueName(ruleValue)"
          class="test_button"
          >{{ __("Add Return Value") }}
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";

const ruleConfigurer = {
  output_name: "",
  output_value: ""
};

export default {
  props: {
    disableSubmit: {
      required: false,
      type: Boolean,
      default: false
    },
    ruleValue: {
      required: true,
      type: String
    },
    resetRuleWhen: {
      required: true,
      type: Boolean
    }
  },

  data() {
    return {
      ruleConfigurer: _.cloneDeep(ruleConfigurer)
    };
  },
  computed: {
    // isJsonPathConfigurationComplete() {
    //   return (
    //     this.jsonPathConfigurer.rule_value.length &&
    //     this.jsonPathConfigurer.variable_name.length &&
    //     !_.some(
    //       this.variableRules,
    //       rule =>
    //         rule.variable_name === this.jsonPathConfigurer.variable_name &&
    //         this.jsonPathConfigurer.variable_id === -1
    //     )
    //   );
    // }
  },

  methods: {
    addReturnValueName(ruleValue) {
      console.log("rule value ", ruleValue);
      this.ruleConfigurer.output_value = ruleValue;

      let newoft_output = this.ruleConfigurer;

      this.resetRule();
      this.$emit("finish", newoft_output);
    },
    resetRule() {
      this.ruleConfigurer = _.cloneDeep(ruleConfigurer);
    }
  },
  watch: {
    ruleValue: {
      immediate: true,
      handler(val) {
        this.ruleConfigurer.output_value = val;
      }
    },
    resetRuleWhen: {
      immediate: true,
      handler(val) {
        if (val) {
          this.resetRule();
        }
      }
    }
  }
};
</script>

<style scoped></style>
