<template>
  <div class="chat-box">
    <el-input
      :placeholder="__('Enter your prompt...')"
      v-model="prompt"
      class="input-chat-box"
      @keydown.native="inputEnterKeypressHandler($event)"
    ></el-input>
    <el-button-group>
      <el-button :disabled="prompt === ''" type="primary" @click="sendChat()"
        >Send</el-button
      >
      <el-button type="primary" @click="clearMsg()">Clear</el-button>
    </el-button-group>
  </div>
</template>
<script>
export default {
  name: "ChatBox",
  data: () => ({
    // To keep the state of the input content
    prompt: ""
  }),
  methods: {
    // We will call this when the form is submitted
    sendChat() {
      // This fires an event which we will handle
      // in the parent component
      this.$emit("sendChat", this.prompt);
      this.prompt = "";
    },
    clearMsg() {
      this.prompt = "";
      this.$emit("clearChat");
    },
    inputEnterKeypressHandler(event) {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        this.sendChat();
      }
    }
  }
};
</script>
<style scoped>
.chat-box {
  width: 100%;
  display: flex;
}

.input-chat-box {
  flex: 1;
  margin-right: 10px;
}

button:disabled {
  opacity: 0.5;
}
</style>
