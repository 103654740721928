<template>
  <el-select
    v-model="regionValue"
    style="width: 100%;"
    :placeholder="__('Select Google Dialogflow agent\'s region')"
    default-first-option
    filterable
    loading-text="fetching regions"
    @change="changeRegionValue"
  >
    <el-option
      v-for="(item, index) in regionsObj"
      :label="index"
      :value="item"
      :key="index"
    >
      <span style="float: left; font-size: 13px; color: #181F29;">{{
        index
      }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px;">{{
        item
      }}</span>
    </el-option>
  </el-select>
</template>

<script>
import _ from "lodash";

export default {
  name: "DialogflowRegionDropdown",
  props: {
    default_region: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      regionValue: "",
      regionsObj: {
        "Global-US": "",
        Belgium: "europe-west1",
        London: "europe-west2",
        Sydney: "australia-southeast1",
        Tokyo: "asia-northeast1"
      }
    };
  },
  mounted() {
    if (!_.isEmpty(this.default_region)) {
      this.setRegion();
    }
  },
  methods: {
    changeRegionValue(regionObj) {
      this.$emit("dialogflow-region-changed", regionObj);
    },
    setRegion() {
      this.regionValue = _.cloneDeep(this.default_region);
    }
  }
};
</script>

<style scoped></style>
