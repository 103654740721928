<template>
  <div v-if="formAction" style="padding: 20px">
    <div>
      <div class="titles">{{ __("Name") }}</div>
      <div class="details">{{ contentForm.oft_name }}</div>
      <div class="titles">{{ __("Description") }}</div>
      <div class="details">{{ contentForm.oft_description }}</div>
      <div class="titles">{{ __("NLP Engine") }}</div>
      <div class="details custom-capitalize">{{ contentForm.nlp_engine }}</div>
    </div>
    <div style="margin-top: 20px;">
      <el-dropdown
        @command="command => handleAction(command)"
        placement="bottom-start"
        v-loading="oft_loading"
      >
        <el-button plain>{{ __("Actions") }}</el-button>
        <el-dropdown-menu slot="dropdown" style="width: 200px;">
          <el-dropdown-item command="edit">
            <img
              class="action-icon"
              :src="require('@/assets/icons/icon-edit.svg')"
            />
            <span>{{ __("Edit") }}</span>
          </el-dropdown-item>
          <el-dropdown-item command="check_in_use">
            <img
              class="action-icon"
              :src="require('@/assets/icons/move-phone-numbers.svg')"
            />
            <span>{{ __("Check in use") }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
      :modal="false"
    >
      <el-scrollbar :native="false">
        <div class="max-vh">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
            :disabled="isArchivedStatus"
          >
            <el-row type="flex" style="padding-top: 20px">
              <el-col :span="6"></el-col>
              <el-col :span="18">
                <el-row type="flex">
                  <h2
                    class="custom-formtype-icon inf-icon inf-icon-open-form"
                  ></h2>
                  <page-header
                    :title="__('Open Formtype')"
                    :contentId="id"
                    style="margin-top: 33px;"
                  />
                </el-row>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="oft_name" label="Name">
                  <el-input
                    v-model="contentForm.oft_name"
                    v-loading="isChecking"
                    :placeholder="__('Specify formtype name')"
                    v-permit="allowedRegex"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-tabs
                  v-model="activeTab"
                  class="tabs"
                  style="font-size: 1.2em;"
                >
                  <el-tab-pane :label="__('Form')" name="form">
                    <el-form-item prop="oft_description" label="Description">
                      <el-input
                        v-model="contentForm.oft_description"
                        :placeholder="
                          __('Add some description about the formtype')
                        "
                      ></el-input>
                    </el-form-item>
                    <el-form-item prop="nlp_engine" label="NLP Engine">
                      <el-select
                        v-model="contentForm.nlp_engine"
                        style="width: 100%;"
                        @change="onNlpEngineChange"
                        filterable
                        default-first-option
                      >
                        <el-option
                          v-for="item in nlpEngineTypesWithLdKey"
                          :label="item.label"
                          :value="item.value.toLowerCase()"
                          :key="item.value.toLowerCase()"
                        />
                      </el-select>
                    </el-form-item>
                    <div v-if="isNlpDialogflow">
                      <el-form-item prop="project_id" :label="__('Project ID')">
                        <el-input
                          class="project-id"
                          v-model="contentForm.project_id"
                          :placeholder="
                            __('Provide Google Dialogflow agent\'s project ID')
                          "
                          @blur="
                            contentForm.project_id = $event.target.value.trim()
                          "
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div v-if="isNlpDialogflow">
                      <el-form-item
                        prop="dialogflow_region"
                        :label="__('Region')"
                      >
                        <dialogflow-region-dropdown
                          :default_region="contentForm.region_id"
                          @dialogflow-region-changed="handleRegionIdChange"
                        >
                        </dialogflow-region-dropdown>
                      </el-form-item>
                    </div>
                    <div v-if="isNlpDialogflowCx">
                      <el-form-item prop="agent_name">
                        <div slot="label">
                          <span slot="label" style="margin-right: 5px;"
                            >{{ __("Agent Name") }}
                          </span>
                          <el-popover
                            placement="top"
                            width="360"
                            trigger="click"
                          >
                            <span v-html="popOverContent"></span>
                            <el-button
                              slot="reference"
                              type="text"
                              size="mini"
                              style="color: #4db3f6;"
                            >
                              {{ __("(example format)") }}
                            </el-button>
                          </el-popover>
                        </div>
                        <el-input
                          v-model="contentForm.agent_name"
                          :placeholder="
                            __('Provide Google Dialogflow CX agent name')
                          "
                        ></el-input>
                      </el-form-item>
                    </div>
                    <div v-else-if="isNlpLex">
                      <el-form-item
                        prop="lex_bot_name"
                        :label="__('Bot Name ID')"
                      >
                        <el-input
                          v-model="contentForm.lex_bot_name"
                          :placeholder="__('Provide Amazon lex bot name here')"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        prop="lex_bot_alias"
                        :label="__('Bot Alias ID')"
                      >
                        <el-input
                          v-model="contentForm.lex_bot_alias"
                          :placeholder="__('Provide Amazon lex bot alias here')"
                        ></el-input>
                      </el-form-item>
                      <el-form-item prop="lex_role_arn" :label="__('Role ARN')">
                        <el-input
                          v-model="contentForm.lex_role_arn"
                          :placeholder="__('Provide Role ARN here')"
                        ></el-input>
                      </el-form-item>
                      <el-form-item prop="lex_region" :label="__('Region')">
                        <el-input
                          v-model="contentForm.lex_region"
                          :placeholder="
                            __('Provide Amazon lex bot\'s region here')
                          "
                        ></el-input>
                      </el-form-item>
                    </div>
                    <el-form-item
                      v-if="showLanguageField"
                      prop="test_language_code"
                      :label="__('Test Language')"
                    >
                      <language-dropdown
                        :default_language="contentForm.test_language_code"
                        return_type="code"
                        :nlp_engine="getNlpEngine"
                        @language-changed="handleLanguageChange"
                      >
                      </language-dropdown>
                    </el-form-item>
                    <div v-if="showAzureOpenAiFields">
                      <el-form-item prop="intent_ids" :label="__('Intents')">
                        <el-select
                          v-model="contentForm.intent_ids"
                          multiple
                          style="width: 100%;"
                          :placeholder="__('Select')"
                        >
                          <el-option
                            v-for="intent in intents"
                            :key="intent.intent_id"
                            :label="intent.name"
                            :value="intent.intent_id"
                          />
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-if="isNlpWatson">
                      <el-form-item
                        prop="assistant_url"
                        :label="__('Assistant URL')"
                      >
                        <el-input
                          v-model="contentForm.assistant_url"
                          :placeholder="
                            __('Provide IBM Watson assistant URL here')
                          "
                        >
                        </el-input>
                      </el-form-item>
                      <el-form-item
                        prop="service_api_key"
                        :label="__('Service API Key')"
                      >
                        <el-input
                          v-model="contentForm.service_api_key"
                          :placeholder="
                            __('Provide IBM Watson service API key here')
                          "
                        >
                        </el-input>
                      </el-form-item>
                    </div>
                    <el-form-item
                      prop="test_query_text"
                      :label="__('Test Query Text')"
                      v-if="!isNlpAzureOpenAi"
                    >
                      <el-input
                        v-model="contentForm.test_query_text"
                        :placeholder="__('Specify query text to test the bot')"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      :label="__('Prompt Tester')"
                      v-if="isNlpAzureOpenAi"
                    >
                      <div class="chatMessages">
                        <Message
                          v-for="message in messages"
                          :key="message.id"
                          :class="[
                            'message',
                            { right: isMineChat(message.author) }
                          ]"
                          :dark="isMineChat(message.author)"
                          :text="message.text"
                          :author="message.author"
                        />
                        <div class="loadingOpenAi" v-if="waitOpenAiResponse">
                          <div class="dot"></div>
                          <div class="dot"></div>
                          <div class="dot"></div>
                        </div>
                        <ChatBox
                          class="chat-box"
                          @sendChat="handleOpenAiPreview"
                          @clearChat="clearOpenAiChat"
                        />
                      </div>
                    </el-form-item>
                    <div v-if="showTestQuery">
                      <el-form-item
                        prop="test_query_parameters"
                        :label="__('Test Query Parameters')"
                      >
                        <el-input
                          type="textarea"
                          :rows="8"
                          v-model="contentForm.test_query_parameters"
                          :placeholder="
                            __('Specify query parameters to test the bot')
                          "
                        >
                        </el-input>
                      </el-form-item>
                    </div>
                    <div v-if="isNlpDialogflow">
                      <el-form-item
                        prop="api_version"
                        :label="__('API Version')"
                      >
                        <el-radio-group v-model="contentForm.api_version">
                          <el-radio-button label="default">{{
                            __("V2")
                          }}</el-radio-button>

                          <el-radio-button label="v2beta1">{{
                            __("V2Beta1")
                          }}</el-radio-button>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                    <div v-if="isNlpDialogflowCx">
                      <el-form-item prop="api_version" label="API Version">
                        <el-radio-group v-model="contentForm.api_version">
                          <el-radio-button label="v3">V3</el-radio-button>

                          <el-radio-button label="v3beta1"
                            >V3Beta1</el-radio-button
                          >
                        </el-radio-group>
                      </el-form-item>
                    </div>
                    <el-form-item
                      :label="__('Response')"
                      class="response"
                      v-if="!isNlpAzureOpenAi"
                    >
                      <el-divider></el-divider>
                      <test-web-service
                        ref="webService"
                        :response="httpResponse"
                        :get-response-using="requestJSONResponse"
                        :test-data="testData"
                        json-path-editable
                        :response-editable="false"
                        :enable-preview-button="!checkPreview"
                        :previewResponsePopoverContent="
                          previewResponsePopoverContent
                        "
                        @change="updateHttpResponse"
                        @requestingResponse="resetRule"
                        @rule-added="addReturnValueNameToTable"
                        :show-meta-tab="false"
                      >
                        <!-- you don't have to include the template if the requirement is assign json path to variable as a rule_value -->
                        <!-- it's already taken care by default slot content in test-web-service component -->
                        <!-- but, added the below template to show how to provide a different content for the slot-->
                        <template
                          #rule="{ruleValue, disableSubmit, resetRuleWhen: resetRule, addRule}"
                        >
                          <!--  @finish event handler is calling 'addRule' method in test-web-service component,
                            not addRule in integration-node component -->

                          <json-path-to-string
                            :disable-submit="disableSubmit"
                            :rule-value="ruleValue"
                            :reset-rule-when="resetRule"
                            @finish="addRule"
                          />
                        </template>
                      </test-web-service>

                      <el-row type="flex" style="margin-top: 20px">
                        <el-col>
                          <el-table
                            class="json-paths"
                            :data="contentForm.oft_outputs"
                            height="250"
                            style="width: 100%;"
                          >
                            <el-table-column
                              :label="__('JSON Path')"
                              prop="output_value"
                            >
                              <template slot-scope="scope">
                                {{
                                  prettify(
                                    jsonPathValue(
                                      scope.row.output_value,
                                      variables
                                    )
                                  )
                                }}
                              </template>
                            </el-table-column>
                            <el-table-column
                              :label="__('Return Value Name')"
                              prop="output_name"
                            >
                            </el-table-column>
                            <el-table-column
                              class-name="test-variable"
                              width="100px"
                            >
                              <template slot-scope="scope">
                                <el-button
                                  type="primary"
                                  class="test-variable-btn"
                                  @click="
                                    evaluateAssignedPath(scope.row.output_value)
                                  "
                                  >{{ __("Test") }}
                                </el-button>
                              </template>
                            </el-table-column>
                            <el-table-column
                              class-name="cell-item-pointer"
                              width="50px"
                            >
                              <template slot-scope="scope">
                                <span @click="removeJsonPath(scope.$index)">
                                  <i class="el-icon-circle-close"></i>
                                </span>
                              </template>
                            </el-table-column>
                          </el-table>
                        </el-col>
                      </el-row>
                    </el-form-item>
                    <div v-if="isNlpAzureOpenAi">
                      <OpenAiResponse
                        class="chat-box"
                        :intentArguments="intentArguments"
                      />
                    </div>
                  </el-tab-pane>
                  <el-tab-pane
                    :label="__('Advanced ASR Settings')"
                    name="advanced_asr_settings"
                  >
                    <el-scrollbar :native="false">
                      <div style="max-height: 90vh">
                        <advanced-speech-parameters
                          :nlp-engine="contentForm.nlp_engine"
                          :speech-complete-timeout="
                            contentForm.speech_complete_timeout
                          "
                          :speech-incomplete-timeout="
                            contentForm.speech_incomplete_timeout
                          "
                          :no-input-timeout="contentForm.no_input_timeout"
                          :speech-start-timeout="
                            contentForm.speech_start_timeout
                          "
                          :inter-result-timeout="
                            contentForm.inter_result_timeout
                          "
                          :barge-in-sensitivity="
                            contentForm.barge_in_sensitivity
                          "
                          :auto-punctuation="contentForm.auto_punctuation"
                          :profanity-filter="contentForm.profanity_filter"
                          :single-utterance="contentForm.single_utterance"
                          @update-nlp-parameter="updateNlpParameters"
                        >
                        </advanced-speech-parameters>
                      </div>
                    </el-scrollbar>
                  </el-tab-pane>
                </el-tabs>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <div style="display: flex;margin-bottom: 20px">
                  <el-button
                    v-if="!isArchivedStatus"
                    type="primary"
                    @click="submitForm(id)"
                    class="submitBtn"
                    >{{ id === -1 ? __("Create") : __("Update") }}
                  </el-button>
                  <el-button @click="handleCancel" class="cancelBtn">{{
                    __("Cancel")
                  }}</el-button>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-scrollbar>
    </el-dialog>

    <el-dialog
      :title="__('Check in use')"
      :visible.sync="checkInUseDialog"
      width="50%"
      class="check-in-use-dialog"
      append-to-body
    >
      <div v-loading="checkInUseLoading">
        <el-tree
          v-if="contentUsages.length"
          :data="contentUsages"
          :props="defaultProps"
          accordion
          style="margin-top: 15px; margin-bottom: 22px;"
        >
        </el-tree>
        <div v-else-if="!checkInUseLoading && !checkInUseErrorMessage">
          {{ __("This item is not currently in use.") }}
        </div>
        <div v-else>
          {{ checkInUseErrorMessage }}
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="__('This content is in use.')"
      :visible.sync="showDeleteConfirmationDialog"
      width="50%"
      class="check-in-use-dialog"
    >
      <div>
        <span>
          <!-- eslint-disable-next-line -->
          {{ __("This content is being used in following places. Delete is not permitted.") }}
        </span>
        <el-tree
          :data="contentUsages"
          :props="defaultProps"
          accordion
          style="margin-top: 15px; margin-bottom: 22px;"
        >
        </el-tree>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showDeleteConfirmationDialog = false">{{
            __("Cancel")
          }}</el-button>
          <el-tooltip class="item" effect="dark" placement="bottom">
            <div style="display: inline-block;margin-left: 10px;">
              <el-button
                v-show="contentUsageCount <= 0"
                type="primary"
                @click="handleDelete(content)"
                >{{ __("Delete") }}</el-button
              >
            </div>
          </el-tooltip>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import { mapActions, mapGetters, mapState } from "vuex";
import PageHeader from "@/components/PageHeader";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import _ from "lodash";
import { getSubKeyObject } from "@/utils/collection";
import TestWebService from "@/views/build/callflow/components/node-type-forms/components/TestWebService";
import LanguageDropdown from "@/components/LanguageDropdown.vue";
import AdvancedSpeechParameters from "@/components/AdvancedSpeechParameters.vue";
// import {
//   customFormType,
//   customFormTypeDisabled,
//   customFormTypeHover
// } from "@/styles/content-palette.scss";
import {
  checkUniqueName,
  previewDialogflowResponse,
  previewLexResponse,
  previewWatsonResponse,
  previewDialogflowCXResponse,
  previewAzureOpenaiResponse,
  previewOpenaiResponse
} from "@/api/openformtypes";
import JsonPathToString from "@/views/build/callflow/components/node-type-forms/components/JsonPathToString";
import { jsonPathValue } from "@/utils/canvas";
import { alphaNumericRegex } from "@/utils/regex";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { prettifyJsonPath } from "@/utils/transformers";
import DialogflowRegionDropdown from "@/components/DialogflowRegionDropdown";
import { CHECK_IN_USE_ACTION, EDIT_ACTION } from "@/constants/contents";
import {
  gptModelList,
  NLP_DIALOGFLOW,
  NLP_DIALOGFLOW_CX,
  NLP_LEX,
  NLP_WATSON,
  NLP_AZURE_OPENAI,
  NLP_OPENAI
} from "@/constants/openFormType";

const ruleConfigurer = {
  output_value: "",
  output_name: ""
};
import Message from "@/views/build/content/openformtypes/components/message.vue";
import ChatBox from "@/views/build/content/openformtypes/components/chatbox.vue";
import OpenAiResponse from "@/views/build/content/openformtypes/components/openAiResponse.vue";

export default {
  mixins: [BaseContentInfoPanel, BaseContent],
  components: {
    OpenAiResponse,
    ChatBox,
    Message,
    DialogflowRegionDropdown,
    PageHeader,
    TestWebService,
    JsonPathToString,
    LanguageDropdown,
    AdvancedSpeechParameters
  },
  data() {
    let validateOftName = async (rule, value, callback) => {
      try {
        // update scenario
        if (this.id === 0 && value !== this.contentFormInfo.oft_name) {
          this.isChecking = true;
          const res = await checkUniqueName(value);
          this.isChecking = false;
          if (res.data.found) {
            callback(new Error(__("Openform type name already exists")));
          } else {
            callback();
          }
        }
        if (this.id === -1) {
          this.isChecking = true;
          const res = await checkUniqueName(value);
          this.isChecking = false;
          if (res.data.found) {
            callback(new Error(__("Openform type name already exists")));
          } else {
            callback();
          }
        }
      } catch (e) {
        console.log(e);
        this.isChecking = false;
      }
    };

    let validateQueryParameters = async (rule, value, callback) => {
      try {
        if (value) {
          JSON.parse(value);
        }
      } catch (e) {
        callback(new Error(__("Please enter a valid JSON")));
      }
    };

    return {
      ruleConfigurer: _.cloneDeep(ruleConfigurer),
      rules: {
        oft_name: [
          { required: true, trigger: "blur", message: __("Name is required") },
          { validator: validateOftName, trigger: "blur" }
        ],
        nlp_engine: [
          {
            required: true,
            trigger: "blur",
            message: __("Select any NLP engine type")
          }
        ],
        project_id: [
          {
            required: true,
            trigger: "blur",
            message: __("Project ID is required")
          }
        ],
        lex_bot_name: [
          {
            required: true,
            trigger: "blur",
            message: __("Lex Bot Name is required")
          }
        ],
        lex_bot_alias: [
          {
            required: true,
            trigger: "blur",
            message: __("Lex Bot Alias is required")
          }
        ],
        lex_role_arn: [
          {
            required: true,
            trigger: "blur",
            message: __("Role ARN is required")
          }
        ],
        lex_region: [
          {
            required: true,
            trigger: "blur",
            message: __("Lex Bot Region is required")
          }
        ],
        test_query_parameters: [
          { validator: validateQueryParameters, trigger: "blur" }
        ],
        assistant_url: [
          {
            required: true,
            trigger: "blur",
            message: __("Watson Assistant URL is required")
          }
        ],
        service_api_key: [
          {
            required: true,
            trigger: "blur",
            message: __("Watson service API key is required")
          }
        ],
        agent_name: [
          {
            required: true,
            trigger: "blur",
            message: __("Dialogflow CX agent name is required")
          }
        ]
      },
      isChecking: false,
      allowedRegex: alphaNumericRegex,
      isSubmitting: false,
      httpResponse: "",
      contentUsages: [],
      checkInUseDialog: false,
      defaultProps: {
        children: "children",
        label: "label"
      },
      showDeleteConfirmationDialog: false,
      publishOpenFormTypeStatus: "disable",
      publishOpenFormTypeConfirmed: "disabled",

      // eslint-disable-next-line
      previewResponsePopoverContent: __("Kindly fill the required fields above to activate Preview Response button"),
      // colorScheme: {
      //   normal: customFormType,
      //   disabled: customFormTypeDisabled,
      //   hover: customFormTypeHover
      // },
      jsonPathValue,
      prettify: prettifyJsonPath,
      additionalValidateRoute: "open-forms",
      isContent: true,
      activeTab: "form",
      watsonRegions: [
        {
          label: __("Sydney"),
          value: "au-syd"
        },
        {
          label: __("Seoul"),
          value: "kr-seo"
        },
        {
          label: __("Tokyo"),
          value: "jp-tok"
        },
        {
          label: __("Frankfurt"),
          value: "eu-de"
        },
        {
          label: __("London"),
          value: "eu-gb"
        },
        {
          label: __("Dallas"),
          value: "us-south"
        },
        {
          label: __("Washington"),
          value: "eu-east"
        }
      ],
      popOverContent:
        `<span>` +
        `<span style="color: var(--theme-color);">` +
        "projects/[project-id]/locations/[region]/agents/[agent_id]" +
        `</span></span>`,
      showNlpEngineOpenAi: false,
      modelList: gptModelList,
      messages: [],
      waitOpenAiResponse: false,
      functionCallTestValues: []
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapState("asrLanguages", {
      asrLanguages: state => state.asrLanguages
    }),
    ...mapState("openFormTypes", {
      oft_loading: state => state.loading,
      nlpEngineTypes: state => state.nlpEngineTypes
    }),
    ...mapState("folders", {
      checkInUseLoading: state => state.checkInUseLoading,
      checkInUseErrorMessage: state => state.checkInUseErrorMessage
    }),
    ...mapState("intents", {
      intents: state => state.intents
    }),
    showLanguageField() {
      return !this.isNlpWatson && !this.isNlpAzureOpenAi && !this.isNlpOpenAi;
    },
    showTestQuery() {
      return this.isNlpDialogflow || this.isNlpWatson || this.isNlpDialogflowCx;
    },
    showOpenAiFields() {
      return this.isNlpOpenAi;
    },
    showAzureOpenAiFields() {
      return this.isNlpAzureOpenAi || this.isNlpOpenAi;
    },
    nlpEngineTypesWithLdKey() {
      return this.nlpEngineTypes.filter(engineType => {
        return engineType.ldHide ? this.showNlpEngineOpenAi : true;
      });
    },
    contentUsageCount() {
      return _.size(this.contentUsages);
    },
    nothingToPreview() {
      return _.isEmpty(this.httpResponse) && !this.requestingHttpResponse;
    },

    testData() {
      let retVal;
      if (this.isNlpLex) {
        retVal = getSubKeyObject(this.contentForm, [
          "ac_id",
          "lex_bot_name",
          "lex_bot_alias",
          "test_query_text",
          "lex_role_arn",
          "lex_region",
          "test_language_code"
        ]);
      } else if (this.isNlpDialogflow) {
        retVal = getSubKeyObject(this.contentForm, [
          "ac_id",
          "project_id",
          "region_id",
          "test_language_code",
          "test_query_text",
          "test_query_parameters",
          "api_version"
        ]);
      } else if (this.isNlpDialogflowCx) {
        retVal = getSubKeyObject(this.contentForm, [
          "ac_id",
          "agent_name",
          "test_language_code",
          "test_query_text",
          "test_query_parameters",
          "api_version"
        ]);
      } else if (this.isNlpAzureOpenAi) {
        retVal = getSubKeyObject(this.contentForm, [
          "intent_ids",
          "test_query_text",
          "nlp_engine"
        ]);
      } else if (this.isNlpOpenAi) {
        retVal = getSubKeyObject(this.contentForm, [
          "intent_ids",
          "nlp_engine",
          "test_query_text",
          "openai_api_key",
          "openai_model"
        ]);
      } else {
        retVal = getSubKeyObject(this.contentForm, [
          "ac_id",
          "assistant_url",
          "service_api_key",
          "test_query_text",
          "test_query_parameters"
        ]);
      }
      retVal = { ...retVal, ac_id: this.selectedAccountId, session_id: "test" };
      return retVal;
    },
    requestJSONResponse() {
      let process = previewDialogflowResponse;
      if (this.isNlpLex) {
        process = previewLexResponse;
      }
      if (this.isNlpWatson) {
        process = previewWatsonResponse;
      }
      if (this.isNlpDialogflowCx) {
        process = previewDialogflowCXResponse;
      }

      if (this.isNlpAzureOpenAi) {
        process = previewAzureOpenaiResponse;
      }

      if (this.isNlpOpenAi) {
        process = previewOpenaiResponse;
      }
      return process;
    },
    getNlpEngine() {
      return this.contentForm.nlp_engine;
    },
    checkPreview() {
      if (this.isNlpLex) {
        return (
          _.isEmpty(this.contentForm.lex_bot_name) ||
          _.isEmpty(this.contentForm.lex_bot_alias) ||
          _.isEmpty(this.contentForm.lex_role_arn) ||
          _.isEmpty(this.contentForm.lex_region) ||
          _.isEmpty(this.contentForm.test_language_code)
        );
      } else if (this.isNlpDialogflow) {
        return (
          _.isEmpty(this.contentForm.test_query_text) ||
          _.isEmpty(this.contentForm.test_language_code) ||
          _.isEmpty(this.contentForm.project_id) ||
          _.isEmpty(this.contentForm.api_version)
        );
      } else if (this.isNlpDialogflowCx) {
        return (
          _.isEmpty(this.contentForm.test_query_text) ||
          _.isEmpty(this.contentForm.test_language_code) ||
          _.isEmpty(this.contentForm.agent_name) ||
          _.isEmpty(this.contentForm.api_version)
        );
      } else if (this.isNlpAzureOpenAi || this.isNlpOpenAi) {
        return (
          _.isEmpty(this.contentForm.test_query_text) ||
          _.isEmpty(this.contentForm.intent_ids)
        );
      } else {
        return (
          _.isEmpty(this.contentForm.assistant_url) ||
          _.isEmpty(this.contentForm.service_api_key)
        );
      }
    },
    ...mapGetters("variables", {
      variables: "variables"
    }),
    ...mapState("folders", {
      selectedFolderId: state => state.selectedFolderId
    }),
    isNlpDialogflow() {
      return this.contentForm.nlp_engine === NLP_DIALOGFLOW;
    },
    isNlpDialogflowCx() {
      return this.contentForm.nlp_engine === NLP_DIALOGFLOW_CX;
    },
    isNlpLex() {
      return this.contentForm.nlp_engine === NLP_LEX;
    },
    isNlpWatson() {
      return this.contentForm.nlp_engine === NLP_WATSON;
    },
    isNlpAzureOpenAi() {
      return this.contentForm.nlp_engine === NLP_AZURE_OPENAI;
    },
    isNlpOpenAi() {
      return this.contentForm.nlp_engine === NLP_OPENAI;
    },
    intentArguments() {
      if (
        (this.isNlpOpenAi || this.isNlpAzureOpenAi) &&
        _.get(this.contentForm, "intent_ids", []).length > 0
      ) {
        return this.intents
          .filter(item => this.contentForm.intent_ids.includes(item.intent_id))
          .flatMap(item =>
            item.intent_entities.map(entity => ({
              outputValue: entity.name,
              testValue: _.get(this.functionCallTestValues, entity.name, "")
            }))
          );
      }
      return [];
    }
  },
  async created() {
    await this.initializeLanguages();
    await this.getIntents();
    EventBus.$on(
      "open-delete-confirmation-dialog-box",
      async ({ content_id, content_model, content }) => {
        this.checkInUseDialog = false;
        this.currentContentData = content;
        this.currentContentModel = content_model;

        await this.checkContentInUse(content_model, content_id)
          .then(() => {
            if (this.contentUsageCount < 1) {
              this.initiateDelete(content);
            } else {
              this.showDeleteConfirmationDialog = true;
            }
          })
          .catch(err => console.log(err));
      }
    );
  },
  methods: {
    ...mapActions("openFormTypes", {
      createOpenFormType: "createOpenFormType",
      updateOpenFormType: "updateOpenFormType",
      updateContent: "updateOpenFormType",
      publishOpenFormTypeAsSystem: "publishOpenFormTypeAsSystem",
      deleteContentMethod: "deleteSystemOpenFormType"
    }),
    ...mapActions("asrLanguages", {
      getASRLanguages: "getASRLanguages"
    }),
    ...mapActions("folders", {
      checkInUse: "checkInSysUse"
    }),
    ...mapActions("intents", {
      getIntents: "getIntents"
    }),
    async initializeLanguages() {
      await this.getASRLanguages({ fetch_all: 1 });
    },
    async getNlpEngineTypesWithLdKey() {
      this.showNlpEngineOpenAi = await this.showFeature(
        this.$getConst("LLM_TEXT_FORM_NODE")
      );
    },
    updateHttpResponse(httpResponse) {
      this.httpResponse = httpResponse;
    },
    addReturnValueNameToTable(newoft_output) {
      this.contentForm.oft_outputs.push(newoft_output);
    },
    resetRule() {
      this.ruleConfigurer = _.cloneDeep(ruleConfigurer);
    },
    removeJsonPath(index) {
      this.contentForm.oft_outputs.splice(index, 1);
    },
    evaluateAssignedPath(path) {
      this.$set(
        this.$refs.webService.$data,
        "showData",
        /^root\[(['"])ws_response_data(['"])]/.test(path)
      );
      this.$refs.webService.evaluateJsonPath(path);
    },

    onNlpEngineChange() {
      let selected = this.contentForm.nlp_engine;
      if (selected === NLP_LEX) {
        this.contentForm.project_id = "";
        this.contentForm.region_id = "";
        this.contentForm.test_query_parameters = "";
        this.contentForm.assistant_url = "";
        this.contentForm.service_api_key = "";
        this.contentForm.api_version = "default";
        this.contentForm.agent_name = "";
      } else if (selected === NLP_DIALOGFLOW) {
        this.contentForm.lex_bot_name = "";
        this.contentForm.lex_bot_alias = "";
        this.contentForm.lex_role_arn = "";
        this.contentForm.lex_region = "";
        this.contentForm.region_id = "";
        this.contentForm.assistant_url = "";
        this.contentForm.service_api_key = "";
        this.contentForm.agent_name = "";
        this.contentForm.api_version = "default";
      } else if (selected === NLP_WATSON) {
        this.contentForm.lex_bot_name = "";
        this.contentForm.lex_bot_alias = "";
        this.contentForm.lex_role_arn = "";
        this.contentForm.lex_region = "";
        this.contentForm.project_id = "";
        this.contentForm.region_id = "";
        this.contentForm.test_query_parameters = "";
        this.contentForm.api_version = "default";
        this.contentForm.agent_name = "";
      } else if (selected === NLP_DIALOGFLOW_CX) {
        this.contentForm.lex_bot_name = "";
        this.contentForm.lex_bot_alias = "";
        this.contentForm.lex_role_arn = "";
        this.contentForm.lex_region = "";
        this.contentForm.project_id = "";
        this.contentForm.region_id = "";
        this.contentForm.test_query_parameters = "";
        this.contentForm.region_id = "";
        this.contentForm.api_version = "v3";
        this.contentForm.assistant_url = "";
        this.contentForm.service_api_key = "";
      } else if (selected === NLP_AZURE_OPENAI || selected === NLP_OPENAI) {
        this.contentForm.lex_bot_name = "";
        this.contentForm.lex_bot_alias = "";
        this.contentForm.lex_role_arn = "";
        this.contentForm.lex_region = "";
        this.contentForm.project_id = "";
        this.contentForm.region_id = "";
        this.contentForm.test_query_parameters = "";
        this.contentForm.region_id = "";
        this.contentForm.api_version = "v3";
        this.contentForm.assistant_url = "";
        this.contentForm.agent_name = "";
        this.contentForm.service_api_key = "";
      }
    },
    nameKeydown(e) {
      if (!/^[a-zA-Z0-9_ ]$/.test(e.key)) {
        e.preventDefault();
      }
    },
    async handleLanguageChange(languageObj) {
      this.contentForm.test_language_code = languageObj;
      // this.contentForm.test_language_code = languageObj.language_code;
    },
    async handleRegionIdChange(regionValue) {
      this.contentForm.region_id = regionValue;
    },

    submitForm(id) {
      this.httpResponse = "";

      this.$refs.contentForm.validate(valid => {
        if (valid) {
          if (!this.isNlpWatson) {
            this.contentForm.assistant_url = "";
            this.contentForm.service_api_key = "";
          }
          this.isSubmitting = true;
          const process =
            id === -1 ? this.createOpenFormType : this.updateOpenFormType;

          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;

              id
                ? this.$message({
                    type: "success",
                    message: __("Open Formtype added Successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Open Formtype updated successfully")
                  });
              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.response.data.message
              });
            });
        }
      });
    },
    handleCancel() {
      this.httpResponse = "";
      this.contentForm = _.cloneDeep(this.content);
      this.handleOpenModal(false);
      this.$emit("handleCancel");
    },

    checkContentInUse(content_model, content_id) {
      return new Promise((resolve, reject) => {
        this.contentUsages = [];
        this.checkInUse({
          content_model,
          content_id
        })
          .then(data => {
            this.contentUsages = [...data];
            resolve();
          })
          .catch(err => reject(err));
      });
    },

    async handleAction(command) {
      switch (command) {
        case EDIT_ACTION:
          this.handleEdit();
          break;
        case CHECK_IN_USE_ACTION:
          await this.checkContentInUse(
            "OpenFormType",
            this.contentForm.open_form_type_id
          );
          this.checkInUseDialog = true;
          break;
      }
    },
    updateNlpParameters(nlp_param, value, default_value) {
      if (value !== false && value !== 0 && !value) {
        value = default_value;
      }
      this.$set(this.contentForm, nlp_param, value);
    },
    clearTestQueryParameters() {
      this.contentForm.test_query_parameters = "";
    },

    handleOpenAiPreview(text) {
      this.addMessage(text);
      this.waitOpenAiResponse = true;
      let openAiTestData = { ...this.testData, messages: this.messages };
      previewOpenaiResponse(openAiTestData)
        .then(res => {
          this.httpResponse = res.data;
          let messageReturn = "";
          if (this.isFunctionCall(res.data)) {
            this.functionCallSetValue(res.data);
            messageReturn =
              "The intent call was successful, you can clear the message and test again.";
          } else {
            messageReturn = _.get(
              res.data,
              "ws_response_data.choices.0.message.content",
              "No return message"
            );
          }

          this.waitOpenAiResponse = false;
          this.addMessage(messageReturn, "OpenAi");
        })
        .catch(err => {
          console.log(err);
        });
    },
    isFunctionCall(response) {
      const functionCallArguments = _.get(
        response,
        "ws_response_data.choices.0.message.function_call.arguments",
        null
      );
      return functionCallArguments !== null;
    },
    functionCallSetValue(response) {
      this.functionCallTestValues = _.get(
        response,
        "ws_response_data.choices.0.message.function_call.arguments",
        {}
      );
    },
    clearOpenAiChat() {
      this.messages = [];
    },
    addMessage(text, author = "You") {
      this.messages.push({
        id: this.messages.length + 1,
        text: text,
        author: author
      });
    },
    isMineChat(author) {
      return author === "You";
    }
  },
  watch: {
    checkInUseErrorMessage: {
      handler() {
        if (this.checkInUseErrorMessage) {
          this.$message({
            type: "error",
            message: this.checkInUseErrorMessage
          });
        }
      }
    },
    ldKeyWatch: {
      immediate: true,
      async handler(newVal) {
        if (newVal === "all") {
          this.showNlpEngineOpenAi = false;
        } else {
          await this.getNlpEngineTypesWithLdKey();
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
@import "~@/styles/element-variables.scss";
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/node_common.scss";

::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.custom-capitalize {
  text-transform: capitalize;
}

.custom-formtype-icon {
  height: 40px;
  width: 50px;
  background-size: cover;
  padding-top: 5px;
  margin-right: 10px;
  margin-top: 32px;
}

json-path-container ::v-deep .el-button--primary.is-disabled {
  color: $--color-white;
  background-color: $content-theme-color !important;
  border-color: $content-theme-color !important;
}

.json-path-result-row {
  background-color: black;
  color: white;
  font-size: 0.75rem;
  height: 25px;
  margin-top: 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.json-path-result-container {
  display: flex;
  justify-content: flex-end;
}

.json-path-result {
  padding-right: 20px;
}

.json-paths ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

::v-deep .el-divider--horizontal {
  margin: 0 0 24px 0 !important;
}

.test_button {
  background-color: $content-theme-color !important;
  border: none;
  color: white;
  width: 100%;

  &:hover {
    background-color: $content-theme-hover-color;
  }
}

img {
  width: 20px;
  height: 20px;
}

.prefix {
  margin-top: 10px;
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}

.chatMessages {
  border: 1px solid lightgrey;
  padding: 10px;
  border-radius: 4px;
}

.messages {
  flex-grow: 1;
  overflow: auto;
  padding: 0 1rem;
}

.message + .message {
  margin-top: 1rem;
}

.message.right {
  margin-left: auto;
}

.chat-box {
  margin-top: 15px;
}

.loadingOpenAi {
  display: flex;
  gap: 0.5rem;
}

.loadingOpenAi .dot {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #333;
  border-radius: 50%;
  animation: bounce 1.5s infinite cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-1.5rem);
  }
}
</style>
