<template>
  <el-row type="flex" style="margin: 20px 0">
    <el-col>
      <el-table
        class="json-paths"
        :data="intentArguments"
        height="320"
        style="width: 100%;"
      >
        <el-table-column
          :label="__('Return Value Name')"
          prop="outputValue"
        ></el-table-column>
        <el-table-column
          :label="__('Test Value')"
          prop="testValue"
        ></el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "OpenAiResponse",
  props: ["intentArguments"]
};
</script>
