<template>
  <div :class="['message', { dark }]">
    <div class="messageBox" v-html="formattedText"></div>
    <h5>{{ author }}</h5>
  </div>
</template>

<script>
export default {
  name: "MessageComponent",
  props: [
    "text", // Content of the message
    "author", // Author of the message
    "dark" // Background variant of the box
  ],
  computed: {
    formattedText() {
      return this.text.replace(/\n\n/g, "<br>");
    }
  }
};
</script>
<style scoped>
.message {
  width: fit-content;
}

.message h5 {
  margin: 0;
  font-size: 0.6rem;
  padding: 0 0 0 0.2rem;
  line-height: 1.5rem;
}

.message.dark h5 {
  text-align: right;
}

.message .messageBox {
  font-size: 0.8rem;
  background: #e9eaf6;
  border-radius: 4px;
  padding: 0 0.5rem;
  width: fit-content;
  line-height: 1.8rem;
}

.message.dark .messageBox {
  background: #586ce2;
  color: white;
}
</style>
